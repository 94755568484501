//alerts
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// articles
export const DL_ADD_ARTICLE = "DL_ADD_ARTICLE";
export const DL_ADD_ARTICLE_ERROR = "DL_ADD_ARTICLE_ERROR";
export const DL_GET_ARTICLES_PAGE = "DL_GET_ARTICLES_PAGE";
export const DL_GET_ARTICLES_PAGE_ERROR = "DL_GET_ARTICLES_PAGE_ERROR";
export const DL_SEARCH_ARTICLES_PAGE = "DL_SEARCH_ARTICLES_PAGE";
export const DL_SEARCH_ARTICLES_PAGE_ERROR = "DL_SEARCH_ARTICLES_PAGE_ERROR";
export const DL_SELECT_ARTICLE = "DL_SELECT_ARTICLE";
export const DL_GET_ALL_ARTICLE_COUNT = "DL_GET_ALL_ARTICLE_COUNT";
export const DL_GET_ALL_ARTICLE_COUNT_ERROR = "DL_GET_ALL_ARTICLE_COUNT_ERROR";
export const DL_GET_ARTICLE_SEARCH_COUNT = "DL_GET_ARTICLE_SEARCH_COUNT";
export const DL_GET_ARTICLE_SEARCH_COUNT_ERROR = "DL_GET_ARTICLE_SEARCH_COUNT_ERROR";
export const DL_UPDATE_ARTICLE = "DL_UPDATE_ARTICLE";
export const DL_UPDATE_ARTICLE_ERROR = "DL_UPDATE_ARTICLE_ERROR";
export const DL_DELETE_ARTICLE = "DL_DELETE_ARTICLE";
export const DL_DELETE_ARTICLE_ERROR = "DL_DELETE_ARTICLE_ERROR";
export const DL_GET_ALL_ARTICLES = "DL_GET_ALL_ARTICLES";
export const DL_GET_ALL_ARTICLES_ERROR = "DL_GET_ALL_ARTICLES_ERROR";

//courses
export const GET_ALL_COURSES = "GET_ALL_COURSES";
export const GET_ALL_COURSES_ERROR = "GET_ALL_COURSES_ERROR";
export const GET_PUBLISHED_COURSES = "GET_PUBLISHED_COURSES";
export const GET_PUBLISHED_COURSES_ERROR = "GET_PUBLISHED_COURSES_ERROR";
export const GET_COURSES_PAGE = "GET_COURSES_PAGE";
export const GET_COURSES_PAGE_ERROR = "GET_COURSES_PAGE_ERROR";
export const SEARCH_COURSES_PAGE = "SEARCH_COURSES_PAGE";
export const SEARCH_COURSES_PAGE_ERROR = "SEARCH_COURSES_PAGE_ERROR";
export const ADD_COURSE = "ADD_COURSE";
export const ADD_COURSE_ERROR = "ADD_COURSE_ERROR";
export const SELECT_COURSE = "SELECT_COURSE";
export const GET_ALL_COURSE_COUNT = "GET_ALL_COURSE_COUNT";
export const GET_ALL_COURSE_COUNT_ERROR = "GET_ALL_COURSE_COUNT_ERROR";
export const UPDATE_COURSE = "UPDATE_COURSE";
export const UPDATE_COURSE_ERROR = "UPDATE_COURSE_ERROR";
export const DELETE_COURSE = "DELETE_COURSE";
export const DELETE_COURSE_ERROR = "DELETE_COURSE_ERROR";


//courses
export const GET_ALL_EVENTS = "GET_ALL_EVENTS";
export const GET_ALL_EVENTS_ERROR = "GET_ALL_EVENTS_ERROR";
export const GET_ALL_PUBLISHED_EVENTS = "GET_ALL_PUBLISHED_EVENTS";
export const GET_ALL_PUBLISHED_EVENTS_ERROR = "GET_ALL_PUBLISHED_EVENTS_ERROR";
export const GET_EVENTS_PAGE = "GET_EVENTS_PAGE";
export const GET_EVENTS_PAGE_ERROR = "GET_EVENTS_PAGE_ERROR";
export const SEARCH_EVENTS_PAGE = "SEARCH_EVENTS_PAGE";
export const SEARCH_EVENTS_PAGE_ERROR = "SEARCH_EVENTS_PAGE_ERROR";
export const ADD_EVENT = "ADD_EVENT";
export const ADD_EVENT_ERROR = "ADD_EVENT_ERROR";
export const SELECT_EVENT = "SELECT_EVENT";
export const GET_ALL_EVENT_COUNT = "GET_ALL_EVENT_COUNT";
export const GET_ALL_EVENT_COUNT_ERROR = "GET_ALL_EVENT_COUNT_ERROR";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const UPDATE_EVENT_ERROR = "UPDATE_EVENT_ERROR";
export const DELETE_EVENT = "DELETE_EVENT";
export const DELETE_EVENT_ERROR = "DELETE_EVENT_ERROR";

//instructors
export const GET_ALL_INSTRUCTORS = "GET_ALL_INSTRUCTORS";
export const GET_ALL_INSTRUCTORS_ERROR = "GET_ALL_INSTRUCTORS_ERROR";
export const GET_INSTRUCTORS_PAGE = "GET_INSTRUCTORS_PAGE";
export const GET_INSTRUCTORS_PAGE_ERROR = "GET_INSTRUCTORS_PAGE_ERROR";
export const SEARCH_INSTRUCTORS_PAGE = "SEARCH_INSTRUCTORS_PAGE";
export const SEARCH_INSTRUCTORS_PAGE_ERROR = "SEARCH_INSTRUCTORS_PAGE_ERROR";
export const ADD_INSTRUCTOR = "ADD_INSTRUCTOR";
export const ADD_INSTRUCTOR_ERROR = "ADD_INSTRUCTOR_ERROR";
export const SELECT_INSTRUCTOR = "SELECT_INSTRUCTOR";
export const GET_ALL_INSTRUCTOR_COUNT = "GET_ALL_INSTRUCTOR_COUNT";
export const GET_ALL_INSTRUCTOR_COUNT_ERROR = "GET_ALL_INSTRUCTOR_COUNT_ERROR";
export const UPDATE_INSTRUCTOR = "UPDATE_INSTRUCTOR";
export const UPDATE_INSTRUCTOR_ERROR = "UPDATE_INSTRUCTOR_ERROR";
export const DELETE_INSTRUCTOR = "DELETE_INSTRUCTOR";
export const DELETE_INSTRUCTOR_ERROR = "DELETE_INSTRUCTOR_ERROR";
export const ADD_INSTRUCTOR_ASSOCIATION = "ADD_INSTRUCTOR_ASSOCIATION";
export const ADD_INSTRUCTOR_ASSOCIATION_ERROR = "ADD_INSTRUCTOR_ASSOCIATION_ERROR";


//categories
export const DL_GET_ALL_CATEGORIES = "DL_GET_ALL_CATEGORIES";
export const DL_GET_ALL_CATEGORIES_ERROR = "DL_GET_ALL_CATEGORIES_ERROR";
export const DL_GET_PUBLISHED_CATEGORIES = "DL_GET_PUBLISHED_CATEGORIES";
export const DL_GET_PUBLISHED_CATEGORIES_ERROR = "DL_GET_PUBLISHED_CATEGORIES_ERROR";
export const DL_GET_CATEGORIES_PAGE = "DL_GET_CATEGORIES_PAGE";
export const DL_GET_CATEGORIES_PAGE_ERROR = "DL_GET_CATEGORIES_PAGE_ERROR";
export const DL_SEARCH_CATEGORIES_PAGE = "DL_SEARCH_CATEGORIES_PAGE";
export const DL_SEARCH_CATEGORIES_PAGE_ERROR = "DL_SEARCH_CATEGORIES_PAGE_ERROR";
export const DL_ADD_CATEGORY = "DL_ADD_CATEGORY";
export const DL_ADD_CATEGORY_ERROR = "DL_ADD_CATEGORY_ERROR";
export const SELECT_CATEGORY_DL = "SELECT_CATEGORY_DL";
export const DL_GET_ALL_CATEGORY_COUNT = "DL_GET_ALL_CATEGORY_COUNT";
export const DL_GET_ALL_CATEGORY_COUNT_ERROR = "DL_GET_ALL_CATEGORY_COUNT_ERROR";
export const DL_UPDATE_CATEGORY = "DL_UPDATE_CATEGORY";
export const DL_UPDATE_CATEGORY_ERROR = "DL_UPDATE_CATEGORY_ERROR";
export const DL_DELETE_CATEGORY = "DL_DELETE_CATEGORY";
export const DL_DELETE_CATEGORY_ERROR = "DL_DELETE_CATEGORY_ERROR";
export const DL_ADD_CATEGORY_ASSOCIATION = "DL_ADD_CATEGORY_ASSOCIATION";
export const DL_ADD_CATEGORY_ASSOCIATION_ERROR = "DL_ADD_CATEGORY_ASSOCIATION_ERROR";
export const DL_ADD_FEATURED_CATEGORY_CONTENT = "DL_ADD_FEATURED_CATEGORY_CONTENT";
export const DL_ADD_FEATURED_CATEGORY_CONTENT_ERROR = "DL_ADD_FEATURED_CATEGORY_CONTENT_ERROR";
export const DL_GET_ALL_FEATURED_CATEGORY_CONTENT = "DL_GET_ALL_FEATURED_CATEGORY_CONTENT";
export const DL_GET_ALL_FEATURED_CATEGORY_CONTENT_ERROR = "DL_GET_ALL_FEATURED_CATEGORY_CONTENT_ERROR";
export const DL_DELETE_FEATURED_CATEGORY_CONTENT_BY_TYPE = "DL_DELETE_FEATURED_CATEGORY_CONTENT_BY_TYPE";
export const DL_DELETE_FEATURED_CATEGORY_CONTENT_BY_TYPE_ERROR = "DL_DELETE_FEATURED_CATEGORY_CONTENT_BY_TYPE_ERROR";

//event locations
export const GET_ALL_LOCATIONS = "GET_ALL_LOCATIONS";
export const GET_ALL_LOCATIONS_ERROR = "GET_ALL_LOCATIONS_ERROR";
export const GET_LOCATIONS_PAGE = "GET_LOCATIONS_PAGE";
export const GET_LOCATIONS_PAGE_ERROR = "GET_LOCATIONS_PAGE_ERROR";
export const SEARCH_LOCATIONS_PAGE = "SEARCH_LOCATIONS_PAGE";
export const SEARCH_LOCATIONS_PAGE_ERROR = "SEARCH_LOCATIONS_PAGE_ERROR";
export const ADD_LOCATION = "ADD_LOCATION";
export const ADD_LOCATION_ERROR = "ADD_LOCATION_ERROR";
export const SELECT_LOCATION = "SELECT_LOCATION";
export const GET_ALL_LOCATION_COUNT = "GET_ALL_LOCATION_COUNT";
export const GET_ALL_LOCATION_COUNT_ERROR = "GET_ALL_LOCATION_COUNT_ERROR";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const UPDATE_LOCATION_ERROR = "UPDATE_LOCATION_ERROR";
export const DELETE_LOCATION = "DELETE_LOCATION";
export const DELETE_LOCATION_ERROR = "DELETE_LOCATION_ERROR";
export const GET_FEATURED_CONTENT_HOME = "GET_FEATURED_CONTENT_HOME";
export const GET_FEATURED_CONTENT_HOME_ERROR = "GET_FEATURED_CONTENT_HOME_ERROR";
export const SET_FEATURED_CONTENT_FALSE = "SET_FEATURED_CONTENT_FALSE";
export const SET_FEATURED_CONTENT_FALSE_ERROR = "SET_FEATURED_CONTENT_FALSE_ERROR";
export const SET_FEATURED_EVENT = "SET_FEATURED_EVENT";
export const SET_FEATURED_EVENT_ERROR = "SET_FEATURED_EVENT_ERROR";



// target audience
export const ADD_AUDIENCE = "ADD_AUDIENCE";
export const ADD_AUDIENCE_ERROR = "ADD_AUDIENCE_ERROR";
export const GET_ALL_AUDIENCES = "GET_ALL_AUDIENCES";
export const GET_ALL_AUDIENCES_ERROR = "GET_ALL_AUDIENCES_ERROR";
export const GET_AUDIENCES_PAGE = "GET_AUDIENCES_PAGE";
export const GET_AUDIENCES_PAGE_ERROR = "GET_AUDIENCES_PAGE_ERROR";
export const SEARCH_AUDIENCES_PAGE = "SEARCH_AUDIENCES_PAGE";
export const SEARCH_AUDIENCES_PAGE_ERROR = "SEARCH_AUDIENCES_PAGE_ERROR";
export const SELECT_AUDIENCE = "SELECT_AUDIENCE";
export const GET_ALL_AUDIENCE_COUNT = "GET_ALL_AUDIENCE_COUNT";
export const GET_ALL_AUDIENCE_COUNT_ERROR = "GET_ALL_AUDIENCE_COUNT_ERROR";
export const UPDATE_AUDIENCE = "UPDATE_AUDIENCE";
export const UPDATE_AUDIENCE_ERROR = "UPDATE_AUDIENCE_ERROR";
export const DELETE_AUDIENCE = "DELETE_AUDIENCE";
export const DELETE_AUDIENCE_ERROR = "DELETE_AUDIENCE_ERROR";
export const ADD_AUDIENCE_ASSOCIATION = "ADD_AUDIENCE_ASSOCIATION";
export const ADD_AUDIENCE_ASSOCIATION_ERROR = "ADD_AUDIENCE_ASSOCIATION_ERROR";


//event course types
export const GET_ALL_COURSETYPES = "GET_ALL_COURSETYPES";
export const GET_ALL_COURSETYPES_ERROR = "GET_ALL_COURSETYPES_ERROR";
export const GET_COURSETYPES_PAGE = "GET_COURSETYPES_PAGE";
export const GET_COURSETYPES_PAGE_ERROR = "GET_COURSETYPES_PAGE_ERROR";
export const SEARCH_COURSETYPES_PAGE = "SEARCH_COURSETYPES_PAGE";
export const SEARCH_COURSETYPES_PAGE_ERROR = "SEARCH_COURSETYPES_PAGE_ERROR";
export const ADD_COURSETYPE = "ADD_COURSETYPE";
export const ADD_COURSETYPE_ERROR = "ADD_COURSETYPE_ERROR";
export const SELECT_COURSETYPE = "SELECT_COURSETYPE";
export const GET_ALL_COURSETYPE_COUNT = "GET_ALL_COURSETYPE_COUNT";
export const GET_ALL_COURSETYPE_COUNT_ERROR = "GET_ALL_COURSETYPE_COUNT_ERROR";
export const UPDATE_COURSETYPE = "UPDATE_COURSETYPE";
export const UPDATE_COURSETYPE_ERROR = "UPDATE_COURSETYPE_ERROR";
export const DELETE_COURSETYPE = "DELETE_COURSETYPE";
export const DELETE_COURSETYPE_ERROR = "DELETE_COURSETYPE_ERROR";


//advertisements
export const GET_ALL_ADVERTISEMENTS = "GET_ALL_ADVERTISEMENTS";
export const GET_ALL_ADVERTISEMENTS_ERROR = "GET_ALL_ADVERTISEMENTS_ERROR";
export const GET_ADVERTISEMENTS_PAGE = "GET_ADVERTISEMENTS_PAGE";
export const GET_ADVERTISEMENTS_PAGE_ERROR = "GET_ADVERTISEMENTS_PAGE_ERROR";
export const SEARCH_ADVERTISEMENTS_PAGE = "SEARCH_ADVERTISEMENTS_PAGE";
export const SEARCH_ADVERTISEMENTS_PAGE_ERROR = "SEARCH_ADVERTISEMENTS_PAGE_ERROR";
export const ADD_ADVERTISEMENT = "ADD_ADVERTISEMENT";
export const ADD_ADVERTISEMENT_ERROR = "ADD_ADVERTISEMENT_ERROR";
export const SELECT_ADVERTISEMENT = "SELECT_ADVERTISEMENT";
export const GET_ALL_ADVERTISEMENT_COUNT = "GET_ALL_ADVERTISEMENT_COUNT";
export const GET_ALL_ADVERTISEMENT_COUNT_ERROR = "GET_ALL_ADVERTISEMENT_COUNT_ERROR";
export const UPDATE_ADVERTISEMENT = "UPDATE_ADVERTISEMENT";
export const UPDATE_ADVERTISEMENT_ERROR = "UPDATE_ADVERTISEMENT_ERROR";
export const DELETE_ADVERTISEMENT = "DELETE_ADVERTISEMENT";
export const DELETE_ADVERTISEMENT_ERROR = "DELETE_ADVERTISEMENT_ERROR";
export const SEARCH_ADVERTISEMENTS_PAGE_BY_PAGE_NAME = "SEARCH_ADVERTISEMENTS_PAGE_BY_PAGE_NAME";
export const SEARCH_ADVERTISEMENTS_PAGE_BY_PAGE_NAME_ERROR = "SEARCH_ADVERTISEMENTS_PAGE_BY_PAGE_NAME_ERROR";
export const GET_ALL_MANUFACTURER_ADVERTISEMENTS = "GET_ALL_MANUFACTURER_ADVERTISEMENTS";
export const GET_ALL_MANUFACTURER_ADVERTISEMENTS_ERROR = "GET_ALL_MANUFACTURER_ADVERTISEMENTS_ERROR";
export const GET_MANUFACTURER_ADVERTISEMENTS = "GET_MANUFACTURER_ADVERTISEMENTS";
export const GET_MANUFACTURER_ADVERTISEMENTS_ERROR = "GET_MANUFACTURER_ADVERTISEMENTS_ERROR";
export const ADD_ADVERTISEMENTS_BULK = "ADD_ADVERTISEMENTS_BULK";
export const ADD_ADVERTISEMENTS_BULK_ERROR = "ADD_ADVERTISEMENTS_BULK_ERROR";
export const DL_GET_ADVERTISEMENTS_CONTENT = "DL_GET_ADVERTISEMENTS_CONTENT";
export const DL_GET_ADVERTISEMENTS_CONTENT_ERROR = "DL_GET_ADVERTISEMENTS_CONTENT_ERROR";


//embed from

export const DL_GET_ALL_EMBED_FORMS = "DL_GET_ALL_EMBED_FORMS"
export const DL_ALL_EMBED_FORMS_ERROR = "DL_ALL_EMBED_FORMS_ERROR"
export const DL_GET_ALL_EMBED_FORMS_PAGE = "DL_GET_ALL_EMBED_FORMS_PAGE"
export const DL_ALL_EMBED_FORMS_PAGE_ERROR = "DL_ALL_EMBED_FORMS_PAGE_ERROR"
export const DL_GET_EMBED_FORM = "DL_GET_EMBED_FORM"
export const DL_GET_EMBED_FORM_ERROR = "DL_GET_EMBED_FORM_ERROR"
export const DL_SELECT_EMBED_FORM = "DL_SELECT_EMBED_FORM"
export const DL_SEARCH_EMBED_FORMS = "DL_SEARCH_EMBED_FORMS"
export const DL_GET_EMBED_FORM_COUNT = "DL_GET_EMBED_FORM_COUNT"
export const DL_GET_EMBED_FORM_COUNT_ERROR = "DL_GET_EMBED_FORM_COUNT_ERROR"
export const DL_ADD_EMBED_FORM = "DL_ADD_EMBED_FORM"
export const DL_ADD_EMBED_FORM_ERROR = "DL_ADD_EMBED_FORM_ERROR"
export const DL_UPDATE_EMBED_FORM = "DL_UPDATE_EMBED_FORM"
export const DL_DELETE_EMBED_FORM = "DL_DELETE_EMBED_FORM"
export const DL_UPDATE_EMBED_FORM_ERROR = "DL_UPDATE_EMBED_FORM_ERROR"
export const DL_DELETE_EMBED_FORM_ERROR = "DL_DELETE_EMBED_FORM_ERROR"
export const DL_SEARCH_EMBED_FORMS_ERROR = "DL_SEARCH_EMBED_FORMS_ERROR"

// Manufacturers
export const GET_DL_COURSE_SUPPORTERS = "GET_DL_COURSE_SUPPORTERS"
export const GET_DL_COURSE_SUPPORTERS_ERROR = "GET_DL_COURSE_SUPPORTERS_ERROR"
export const GET_DL_MANUFACTURERS = "GET_DL_MANUFACTURERS"
export const GET_DL_MANUFACTURERS_ERROR = "GET_DL_MANUFACTURERS_ERROR"
export const DL_SEARCH_MANUFACTURERS = "DL_SEARCH_MANUFACTURERS"
export const DL_SEARCH_MANUFACTURERS_ERROR = "DL_SEARCH_MANUFACTURERS_ERROR"
export const DL_SELECT_MANUFACTURER = "DL_SELECT_MANUFACTURER"
export const DL_DELETE_MANUFACTURER = "DL_DELETE_MANUFACTURER"
export const DL_DELETE_MANUFACTURER_ERROR = "DL_DELETE_MANUFACTURER_ERROR"
export const DL_ADD_MANUFACTURER = "DL_ADD_MANUFACTURER"
export const DL_ADD_MANUFACTURER_ERROR = "DL_ADD_MANUFACTURER_ERROR"
export const DL_UPDATE_MANUFACTURER = "DL_UPDATE_MANUFACTURER"
export const DL_UPDATE_MANUFACTURER_ERROR = "DL_UPDATE_MANUFACTURER_ERROR"
export const GET_DL_MANUFACTURERS_OPTIONS = "GET_DL_MANUFACTURERS_OPTIONS"
export const GET_DL_MANUFACTURERS_OPTIONS_ERROR = "GET_DL_MANUFACTURERS_OPTIONS_ERROR"

// Take 5 videos
export const GET_SHORT_VIDEOS = "GET_SHORT_VIDEOS"
export const GET_SHORT_VIDEOS_ERROR = "GET_SHORT_VIDEOS_ERROR"
export const SEARCH_SHORT_VIDEOS = "SEARCH_SHORT_VIDEOS"
export const SEARCH_SHORT_VIDEOS_ERROR = "SEARCH_SHORT_VIDEOS_ERROR"
export const ADD_SHORT_VIDEOS = "ADD_SHORT_VIDEOS"
export const ADD_SHORT_VIDEOS_ERROR = "ADD_SHORT_VIDEOS_ERROR"
export const UPDATE_SHORT_VIDEOS = "UPDATE_SHORT_VIDEOS"
export const UPDATE_SHORT_VIDEOS_ERROR = "UPDATE_SHORT_VIDEOS_ERROR"
export const DELETE_SHORT_VIDEOS = "DELETE_SHORT_VIDEOS"
export const DELETE_SHORT_VIDEOS_ERROR = "DELETE_SHORT_VIDEOS_ERROR"
export const SELECT_SHORT_VIDEO = "SELECT_SHORT_VIDEO"
export const GET_SHORT_VIDEOS_COUNT = "GET_SHORT_VIDEOS_COUNT"
export const GET_SHORT_VIDEOS_COUNT_ERROR = "GET_SHORT_VIDEOS_COUNT_ERROR"

// Users
export const DL_GET_ALL_USERS_PAGINATED = "DL_GET_ALL_USERS_PAGINATED"
export const DL_GET_ALL_USERS_PAGINATED_ERROR = "DL_GET_ALL_USERS_PAGINATED_ERROR"
export const DL_GET_USERS_SEARCH_PAGINATED = "DL_GET_USERS_SEARCH_PAGINATED"
export const DL_GET_USERS_SEARCH_PAGINATED_ERROR = "DL_GET_USERS_SEARCH_PAGINATED_ERROR"
export const DL_GET_USER_BY_ID = "DL_GET_USER_BY_ID"
export const DL_GET_USER_BY_ID_ERROR = "DL_GET_USER_BY_ID_ERROR"
export const DL_UPDATE_USER = "DL_UPDATE_USER"
export const DL_UPDATE_USER_ERROR = "DL_UPDATE_USER_ERROR"
export const DL_GET_ALL_USERS = "DL_GET_ALL_USERS"
export const DL_GET_ALL_USERS_ERROR = "DL_GET_ALL_USERS_ERROR"
export const DL_SEARCH_USER_EMAILS = "DL_SEARCH_USER_EMAILS"
export const DL_SEARCH_USER_EMAILS_ERROR = "DL_SEARCH_USER_EMAILS_ERROR"
export const DL_BULK_USER = "DL_BULK_USER"
export const DL_BULK_USER_ERROR = "DL_BULK_USER_ERROR"
export const DL_GET_DASHBOARD_USER_STATS = "DL_GET_DASHBOARD_USER_STATS"
export const DL_GET_DASHBOARD_USER_STATS_ERROR = "DL_GET_DASHBOARD_USER_STATS_ERROR"
export const DL_GET_DASHBOARD_USER_STATS_LOADING = "DL_GET_DASHBOARD_USER_STATS_LOADING"
export const DL_GET_USER_TOTAL_STATS = "DL_GET_USER_TOTAL_STATS"
export const DL_GET_USER_TOTAL_STATS_ERROR = "DL_GET_USER_TOTAL_STATS_ERROR"
export const DL_GET_REVENUE_STATS = "DL_GET_REVENUE_STATS"
export const DL_GET_REVENUE_STATS_ERROR = "DL_GET_REVENUE_STATS_ERROR"

// Content Feed
export const DL_GET_CONTENT = "DL_GET_CONTENT"
export const DL_GET_CONTENT_ERROR = "DL_GET_CONTENT_ERROR"
export const DL_ADD_FEATURED = "DL_ADD_FEATURED"
export const DL_ADD_FEATURED_ERROR = "DL_ADD_FEATURED_ERROR"
export const DL_GET_FEATURED_CATEGORY = "DL_GET_FEATURED_CATEGORY"
export const DL_GET_FEATURED_SPONSOR = "DL_GET_FEATURED_SPONSOR"
export const DL_GET_FEATURED_GENERAL = "DL_GET_FEATURED_GENERAL"
export const DL_GET_FEATURED_TAKEOVER = "DL_GET_FEATURED_TAKEOVER"
export const DL_GET_FEATURED_COURSE = "DL_GET_FEATURED_COURSE"
export const DL_GET_FEATURED_EVENT = "DL_GET_FEATURED_EVENT"
export const DL_GET_FEATURED_ERROR = "DL_GET_FEATURED_ERROR"
export const DL_SELECT_CATEGORY = "DL_SELECT_CATEGORY"
export const DL_SELECT_FEATURED_MANUFACTURER = "DL_SELECT_FEATURED_MANUFACTURER"
export const DL_SELECT_COURSE = "DL_SELECT_COURSE"
export const DL_SELECT_EVENT = "DL_SELECT_EVENT"
export const DL_DELETE_FEATURED = "DL_DELETE_FEATURED"
export const DL_DELETE_FEATURED_ERROR = "DL_DELETE_FEATURED_ERROR"

// Pop ups
export const DL_GET_PUBLISHED_POPUPS = "DL_GET_PUBLISHED_POPUPS"
export const DL_GET_PUBLISHED_POPUPS_ERROR = "DL_GET_PUBLISHED_POPUPS_ERROR"
export const DL_GET_POPUPS = "DL_GET_POPUPS"
export const DL_GET_POPUPS_ERROR = "DL_GET_POPUPS_ERROR"
export const DL_SEARCH_POPUPS = "DL_SEARCH_POPUPS"
export const DL_SEARCH_POPUPS_ERROR = "DL_SEARCH_POPUPS_ERROR"
export const DL_GET_POPUPS_COUNT = "DL_GET_POPUPS_COUNT"
export const DL_GET_POPUPS_COUNT_ERROR = "DL_GET_POPUPS_COUNT_ERROR"
export const DL_GET_POPUPS_SEARCH_COUNT = "DL_GET_POPUPS_SEARCH_COUNT"
export const ADD_POPUP = "ADD_POPUP"
export const ADD_POPUP_ERROR = "ADD_POPUP_ERROR"
export const UPDATE_POPUP = "UPDATE_POPUP"
export const UPDATE_POPUP_ERROR = "UPDATE_POPUP_ERROR"
export const DELETE_POPUP = "DELETE_POPUP"
export const DELETE_POPUP_ERROR = "DELETE_POPUP_ERROR"
export const DL_SELECT_POPUP = "DL_SELECT_POPUP"

// Featured
export const DL_GET_CONTENT_TYPE = "DL_GET_CONTENT_TYPE"
export const DL_GET_CONTENT_TYPE_ERROR = "DL_GET_CONTENT_TYPE_ERROR"
export const GET_FEATURED_HOME_CONTENT = "GET_FEATURED_HOME_CONTENT"
export const GET_FEATURED_HOME_CONTENT_ERROR = "GET_FEATURED_HOME_CONTENT_ERROR"
export const DL_ADD_FEATURED_CONTENT = "DL_ADD_FEATURED_CONTENT"
export const DL_ADD_FEATURED_CONTENT_ERROR = "DL_ADD_FEATURED_CONTENT_ERROR"

// Bundles
export const DL_ADD_BUNDLE = "DL_ADD_BUNDLE"
export const DL_ADD_BUNDLE_ERROR = "DL_ADD_BUNDLE_ERROR"
export const DL_GET_ALL_BUNDLES = "DL_GET_ALL_BUNDLES"
export const DL_GET_ALL_BUNDLES_ERROR = "DL_GET_ALL_BUNDLES_ERROR"
export const DL_GET_ALL_PUBLISHED_BUNDLES = "DL_GET_ALL_PUBLISHED_BUNDLES"
export const DL_GET_ALL_PUBLISHED_BUNDLES_ERROR = "DL_GET_ALL_PUBLISHED_BUNDLES_ERROR"
export const DL_GET_BUNDLE_COURSES = "DL_GET_BUNDLE_COURSES"
export const DL_GET_BUNDLE_COURSES_ERROR = "DL_GET_BUNDLE_COURSES_ERROR"
export const DL_GET_BUNDLES_PAGE = "DL_GET_BUNDLES_PAGE"
export const DL_GET_BUNDLES_PAGE_ERROR = "DL_GET_BUNDLES_PAGE_ERROR"
export const DL_SEARCH_BUNDLES_PAGE = "DL_SEARCH_BUNDLES_PAGE"
export const DL_SEARCH_BUNDLES_PAGE_ERROR = "DL_SEARCH_BUNDLES_PAGE_ERROR"
export const DL_SELECT_BUNDLE = "DL_SELECT_BUNDLE"
export const DL_UPDATE_BUNDLE = "DL_UPDATE_BUNDLE"
export const DL_UPDATE_BUNDLE_ERROR = "DL_UPDATE_BUNDLE_ERROR"
export const DL_DELETE_BUNDLE = "DL_DELETE_BUNDLE"
export const DL_DELETE_BUNDLE_ERROR = "DL_DELETE_BUNDLE_ERROR"
export const DL_GET_ALL_BUNDLE_COUNT = "DL_GET_ALL_BUNDLE_COUNT"
export const DL_GET_ALL_BUNDLE_COUNT_ERROR = "DL_GET_ALL_BUNDLE_COUNT_ERROR"
export const DL_SEARCH_BUNDLES_COUNT = "DL_SEARCH_BUNDLES_COUNT"
export const DL_SEARCH_BUNDLES_COUNT_ERROR = "DL_SEARCH_BUNDLES_COUNT_ERROR"
export const DL_GET_BUNDLED_COURSE_IDS = "DL_GET_BUNDLED_COURSE_IDS"
export const DL_GET_BUNDLED_COURSE_IDS_ERROR = "DL_GET_BUNDLED_COURSE_IDS_ERROR"
export const DL_GET_PUBLISHED_BUNDLES_AND_LICENSE_COUNT = "DL_GET_PUBLISHED_BUNDLES_AND_LICENSE_COUNT"
export const DL_GET_PUBLISHED_BUNDLES_AND_LICENSE_COUNT_ERROR = "DL_GET_PUBLISHED_BUNDLES_AND_LICENSE_COUNT_ERROR"
export const DL_SEARCH_BUNDLES_WITH_LICENSES = "DL_SEARCH_BUNDLES_WITH_LICENSES"
export const DL_SEARCH_BUNDLES_WITH_LICENSES_ERROR = "DL_SEARCH_BUNDLES_WITH_LICENSES_ERROR"
export const DL_GET_ALL_BUNDLE_OPTIONS = "DL_GET_ALL_BUNDLE_OPTIONS"
export const DL_GET_ALL_BUNDLE_OPTIONS_ERROR = "DL_GET_ALL_BUNDLE_OPTIONS_ERROR"

// Transactions
export const DL_GET_ALL_TRANSACTIONS_PAGINATED = "DL_GET_ALL_TRANSACTIONS_PAGINATED"
export const DL_GET_ALL_TRANSACTIONS_PAGINATED_ERROR = "DL_GET_ALL_TRANSACTIONS_PAGINATED_ERROR"
export const DL_GET_ALL_TRANSACTIONS_PAGINATED_BY_SEARCH_TERM = "DL_GET_ALL_TRANSACTIONS_PAGINATED_BY_SEARCH_TERM"
export const DL_GET_ALL_TRANSACTIONS_PAGINATED_BY_SEARCH_TERM_ERROR = "DL_GET_ALL_TRANSACTIONS_PAGINATED_BY_SEARCH_TERM_ERROR"
export const DL_GET_TRANSACTION_RECEIPT = "DL_GET_TRANSACTION_RECEIPT"
export const DL_GET_TRANSACTION_RECEIPT_ERROR = "DL_GET_TRANSACTION_RECEIPT_ERROR"
export const DL_REFUND_TRANSACTION = "DL_REFUND_TRANSACTION"
export const DL_REFUND_TRANSACTION_ERROR = "DL_REFUND_TRANSACTION_ERROR"
export const DL_GET_ALL_USER_TRANSACTIONS_BY_USER_ID = "DL_GET_ALL_USER_TRANSACTIONS_BY_USER_ID"
export const DL_GET_ALL_USER_TRANSACTIONS_BY_USER_ID_ERROR = "DL_GET_ALL_USER_TRANSACTIONS_BY_USER_ID_ERROR"
export const DL_GET_TRANSACTION_COUPONS = "DL_GET_TRANSACTION_COUPONS"
export const DL_GET_TRANSACTION_COUPONS_ERROR = "DL_GET_TRANSACTION_COUPONS_ERROR"
export const DL_GET_EXTERNAL_TRANSACTIONS = "DL_GET_EXTERNAL_TRANSACTIONS"
export const DL_GET_EXTERNAL_TRANSACTIONS_ERROR = "DL_GET_EXTERNAL_TRANSACTIONS_ERROR"
export const DL_GET_EXTERNAL_TRANSACTIONS_COUNT = "DL_GET_EXTERNAL_TRANSACTIONS_COUNT"
export const DL_GET_EXTERNAL_TRANSACTIONS_COUNT_ERROR = "DL_GET_EXTERNAL_TRANSACTIONS_COUNT_ERROR"
export const DL_SELECT_EXTERNAL_TRANSACTION = "DL_SELECT_EXTERNAL_TRANSACTION"
export const DL_DISABLE_TRANSACTION_EDIT = "DL_DISABLE_TRANSACTION_EDIT"

// Subscriptions
export const DL_GET_ALL_SUBSCRIPTIONS_PAGINATED = "DL_GET_ALL_SUBSCRIPTIONS_PAGINATED"
export const DL_GET_ALL_SUBSCRIPTIONS_PAGINATED_ERROR = "DL_GET_ALL_SUBSCRIPTIONS_PAGINATED_ERROR"
export const DL_GET_ALL_SUBSCRIPTIONS_PAGINATED_BY_SEARCH_TERM = "DL_GET_ALL_SUBSCRIPTIONS_PAGINATED_BY_SEARCH_TERM"
export const DL_GET_ALL_SUBSCRIPTIONS_PAGINATED_BY_SEARCH_TERM_ERROR = "DL_GET_ALL_SUBSCRIPTIONS_PAGINATED_BY_SEARCH_TERM_ERROR"
export const DL_GET_ALL_USERS_SUBSCRIPTIONS_BY_USER_ID = "DL_GET_ALL_USERS_SUBSCRIPTIONS_BY_USER_ID"
export const DL_GET_ALL_USERS_SUBSCRIPTIONS_BY_USER_ID_ERROR = "DL_GET_ALL_USERS_SUBSCRIPTIONS_BY_USER_ID_ERROR"
export const DL_SUBSCRIPTION_CANCEL_AUTO_RENEW = "DL_SUBSCRIPTION_CANCEL_AUTO_RENEW"
export const DL_SUBSCRIPTION_CANCEL_AUTO_RENEW_ERROR = "DL_SUBSCRIPTION_CANCEL_AUTO_RENEW_ERROR"
export const DL_GET_SUBSCRIPTION_COUPONS = "DL_GET_SUBSCRIPTION_COUPONS"
export const DL_GET_SUBSCRIPTION_COUPONS_ERROR = "DL_GET_SUBSCRIPTION_COUPONS_ERROR"


// Registrations
export const DL_GET_ALL_REGISTRATIONS_PAGINATED = "DL_GET_ALL_REGISTRATIONS_PAGINATED"
export const DL_GET_ALL_REGISTRATIONS_PAGINATED_ERROR = "DL_GET_ALL_REGISTRATIONS_PAGINATED_ERROR"
export const DL_GET_SEARCH_ALL_REGISTRATIONS_PAGINATED_BY_SEARCH_TERM = "DL_GET_SEARCH_ALL_REGISTRATIONS_PAGINATED_BY_SEARCH_TERM"
export const DL_GET_SEARCH_ALL_REGISTRATIONS_PAGINATED_BY_SEARCH_TERM_ERROR = "DL_GET_SEARCH_ALL_REGISTRATIONS_PAGINATED_BY_SEARCH_TERM_ERROR"
export const DL_GET_ALL_REGISTRATIONS_BY_COMPLETION_STATUS_PAGINATED = "DL_GET_ALL_REGISTRATIONS_BY_COMPLETION_STATUS_PAGINATED"
export const DL_GET_ALL_REGISTRATIONS_BY_COMPLETION_STATUS_PAGINATED_ERROR = "DL_GET_ALL_REGISTRATIONS_BY_COMPLETION_STATUS_PAGINATED_ERROR"
export const DL_GET_ALL_REGISTRANTS_TO_ADD_USER_IDS = "DL_GET_ALL_REGISTRANTS_TO_ADD_USER_IDS"
export const DL_GET_ALL_REGISTRANTS_TO_ADD_USER_IDS_ERROR = "DL_GET_ALL_REGISTRANTS_TO_ADD_USER_IDS_ERROR"
export const DL_POST_SAVE_NEW_REGISTRANTS_ON_DB = "DL_POST_SAVE_NEW_REGISTRANTS_ON_DB"
export const DL_POST_SAVE_NEW_REGISTRANTS_ON_DB_ERROR = "DL_POST_SAVE_NEW_REGISTRANTS_ON_DB_ERROR"
export const DL_POST_CREATE_NEW_EVALUATIONS_ON_DB = "DL_POST_CREATE_NEW_EVALUATIONS_ON_DB"
export const DL_POST_CREATE_NEW_EVALUATIONS_ON_DB_ERROR = "DL_POST_CREATE_NEW_EVALUATIONS_ON_DB_ERROR"

// Banner
export const DL_GET_BANNERS = "DL_GET_BANNERS"
export const DL_GET_BANNERS_ERROR = "DL_GET_BANNERS_ERROR"
export const DL_ADD_BANNERS = "DL_ADD_BANNERS"
export const DL_ADD_BANNERS_ERROR = "DL_ADD_BANNERS_ERROR"
export const DL_UPDATE_BANNERS = "DL_UPDATE_BANNERS"
export const DL_UPDATE_BANNERS_ERROR = "DL_UPDATE_BANNERS_ERROR"
export const DL_DELETE_BANNERS = "DL_DELETE_BANNERS"
export const DL_DELETE_BANNERS_ERROR = "DL_DELETE_BANNERS_ERROR"
export const DL_SELECT_BANNER = "DL_SELECT_BANNER"

// Blogs
export const DL_GET_ALL_BLOGS_PAGINATED = "DL_GET_ALL_BLOGS_PAGINATED"
export const DL_GET_ALL_BLOGS_PAGINATED_ERROR = "DL_GET_ALL_BLOGS_PAGINATED_ERROR"
export const DL_CREATE_NEW_BLOG = "DL_CREATE_NEW_BLOG"
export const DL_CREATE_NEW_BLOG_ERROR = "DL_CREATE_NEW_BLOG_ERROR"
export const DL_UPDATE_BLOG = "DL_UPDATE_BLOG"
export const DL_UPDATE_BLOG_ERROR = "DL_UPDATE_BLOG_ERROR"
export const DL_GET_SPECIFIC_BLOG_BY_ID = "DL_GET_SPECIFIC_BLOG_BY_ID"
export const DL_GET_SPECIFIC_BLOG_BY_ID_ERROR = "DL_GET_SPECIFIC_BLOG_BY_ID_ERROR"
export const DL_SEARCH_BLOGS_PAGINATED = "DL_SEARCH_BLOGS_PAGINATED"
export const DL_SEARCH_BLOGS_PAGINATED_ERROR = "DL_SEARCH_BLOGS_PAGINATED_ERROR"
export const DL_DELETE_BLOG_BY_ID = "DL_DELETE_BLOG_BY_ID"
export const DL_DELETE_BLOG_BY_ID_ERROR = "DL_DELETE_BLOG_BY_ID_ERROR"

// Course Licenses
export const DL_GRANT_COURSE_LICENSE = "DL_GRANT_COURSE_LICENSE"
export const DL_GRANT_COURSE_LICENSE_ERROR = "DL_GRANT_COURSE_LICENSE_ERROR"
export const DL_GET_COURSE_LICENSES_PAGINATED = "DL_GET_COURSE_LICENSES_PAGINATED"
export const DL_GET_COURSE_LICENSES_PAGINATED_ERROR = "DL_GET_COURSE_LICENSES_PAGINATED_ERROR"
export const DL_GET_COURSE_LICENSES_COUNT = "DL_GET_COURSE_LICENSES_COUNT"
export const DL_GET_COURSE_LICENSES_COUNT_ERROR = "DL_GET_COURSE_LICENSES_COUNT_ERROR"
export const DL_SEARCH_COURSE_LICENSES_PAGINATED = "DL_SEARCH_COURSE_LICENSES_PAGINATED"
export const DL_SEARCH_COURSE_LICENSES_PAGINATED_ERROR = "DL_SEARCH_COURSE_LICENSES_PAGINATED_ERROR"
export const DL_SEARCH_COURSE_LICENSE_COUNT = "DL_SEARCH_COURSE_LICENSE_COUNT"
export const DL_SEARCH_COURSE_LICENSE_COUNT_ERROR = "DL_SEARCH_COURSE_LICENSE_COUNT_ERROR"
export const DL_SELECT_COURSE_LICENSE = "DL_SELECT_COURSE_LICENSE"
export const DL_DELETE_COURSE_LICENSE = "DL_DELETE_COURSE_LICENSE"
export const DL_DELETE_COURSE_LICENSE_ERROR = "DL_DELETE_COURSE_LICENSE_ERROR"
export const DL_GET_COURSE_LICENSE_USERS = "DL_GET_COURSE_LICENSE_USERS"
export const DL_GET_COURSE_LICENSE_USERS_ERROR = "DL_GET_COURSE_LICENSE_USERS_ERROR"

// Free Memberships
export const DL_GET_FREE_MEMBERSHIPS = "DL_GET_FREE_MEMBERSHIPS"
export const DL_GET_FREE_MEMBERSHIPS_ERROR = "DL_GET_FREE_MEMBERSHIPS_ERROR"
export const DL_GET_FREE_MEMBERSHIP_COUNT = "DL_GET_FREE_MEMBERSHIP_COUNT"
export const DL_GET_FREE_MEMBERSHIP_COUNT_ERROR = "DL_GET_FREE_MEMBERSHIP_COUNT_ERROR"
export const DL_SEARCH_FREE_MEMBERSHIPS = "DL_SEARCH_FREE_MEMBERSHIPS"
export const DL_SEARCH_FREE_MEMBERSHIPS_ERROR = "DL_SEARCH_FREE_MEMBERSHIPS_ERROR"
export const DL_SEARCH_FREE_MEMBERSHIP_COUNT = "DL_SEARCH_FREE_MEMBERSHIP_COUNT"
export const DL_SEARCH_FREE_MEMBERSHIP_COUNT_ERROR = "DL_SEARCH_FREE_MEMBERSHIP_COUNT_ERROR"

// Competitor Exclusion
export const DL_GET_COMPETITOR_EXCLUSIONS = "DL_GET_COMPETITOR_EXCLUSIONS"
export const DL_GET_COMPETITOR_EXCLUSIONS_ERROR = "DL_GET_COMPETITOR_EXCLUSIONS_ERROR"
export const DL_GET_COMPETITOR_EXCLUSIONS_COUNT = "DL_GET_COMPETITOR_EXCLUSIONS_COUNT"
export const DL_GET_COMPETITOR_EXCLUSIONS_COUNT_ERROR = "DL_GET_COMPETITOR_EXCLUSIONS_COUNT_ERROR"
export const DL_SEARCH_COMPETITOR_EXCLUSIONS = "DL_SEARCH_COMPETITOR_EXCLUSIONS"
export const DL_SEARCH_COMPETITOR_EXCLUSIONS_ERROR = "DL_SEARCH_COMPETITOR_EXCLUSIONS_ERROR"
export const DL_SEARCH_COMPETITOR_EXCLUSIONS_COUNT = "DL_SEARCH_COMPETITOR_EXCLUSIONS_COUNT"
export const DL_SEARCH_COMPETITOR_EXCLUSIONS_COUNT_ERROR = "DL_SEARCH_COMPETITOR_EXCLUSIONS_COUNT_ERROR"
export const DL_ADD_COMPETITOR_EXCLUSIONS = "DL_ADD_COMPETITOR_EXCLUSIONS"
export const DL_ADD_COMPETITOR_EXCLUSIONS_ERROR = "DL_ADD_COMPETITOR_EXCLUSIONS_ERROR"
export const DL_UPDATE_COMPETITOR_EXCLUSIONS = "DL_UPDATE_COMPETITOR_EXCLUSIONS"
export const DL_UPDATE_COMPETITOR_EXCLUSIONS_ERROR = "DL_UPDATE_COMPETITOR_EXCLUSIONS_ERROR"
export const DL_DELETE_COMPETITOR_EXCLUSIONS = "DL_DELETE_COMPETITOR_EXCLUSIONS"
export const DL_DELETE_COMPETITOR_EXCLUSIONS_ERROR = "DL_DELETE_COMPETITOR_EXCLUSIONS_ERROR"
export const DL_SELECT_COMPETITOR_EXCLUSION = "DL_SELECT_COMPETITOR_EXCLUSION"
export const DL_GET_COMPETITOR_EXCLUDED_IDS = "DL_GET_COMPETITOR_EXCLUDED_IDS"
export const DL_GET_COMPETITOR_EXCLUDED_IDS_ERROR = "DL_GET_COMPETITOR_EXCLUDED_IDS_ERROR"
export const DL_GET_ALL_COMPETITOR_EXCLUDED_IDS = "DL_GET_ALL_COMPETITOR_EXCLUDED_IDS"
export const DL_GET_ALL_COMPETITOR_EXCLUDED_IDS_ERROR = "DL_GET_ALL_COMPETITOR_EXCLUDED_IDS_ERROR"

// Course Completions
export const DL_GET_COMPLETED_COURSE_PAGINATED = "DL_GET_COMPLETED_COURSE_PAGINATED"
export const DL_GET_COMPLETED_COURSE_PAGINATED_ERROR = "DL_GET_COMPLETED_COURSE_PAGINATED_ERROR"
export const DL_GET_COMPLETED_COURSE_COUNT = "DL_GET_COMPLETED_COURSE_COUNT"
export const DL_GET_COMPLETED_COURSE_COUNT_ERROR = "DL_GET_COMPLETED_COURSE_COUNT_ERROR"

// Axiom Webhook
export const DL_AXIOM_LOADING = "DL_AXIOM_LOADING"
export const DL_GET_AXIOM_WEBHOOK = "DL_GET_AXIOM_WEBHOOK"
export const DL_GET_AXIOM_WEBHOOK_ERROR = "DL_GET_AXIOM_WEBHOOK_ERROR"
export const DL_GET_AXIOM_WEBHOOK_COUNT = "DL_GET_AXIOM_WEBHOOK_COUNT"
export const DL_GET_AXIOM_WEBHOOK_COUNT_ERROR = "DL_GET_AXIOM_WEBHOOK_COUNT_ERROR"
export const DL_SEARCH_AXIOM_WEBHOOK = "DL_SEARCH_AXIOM_WEBHOOK"
export const DL_SEARCH_AXIOM_WEBHOOK_ERROR = "DL_SEARCH_AXIOM_WEBHOOK_ERROR"
export const DL_SEARCH_AXIOM_WEBHOOK_COUNT = "DL_SEARCH_AXIOM_WEBHOOK_COUNT"
export const DL_SEARCH_AXIOM_WEBHOOK_COUNT_ERROR = "DL_SEARCH_AXIOM_WEBHOOK_COUNT_ERROR"

// Podcasts
export const DL_GET_PODCASTS = "DL_GET_PODCASTS"
export const DL_GET_PODCASTS_ERROR = "DL_GET_PODCASTS_ERROR"
export const DL_GET_PUBLISHED_PODCASTS = "DL_GET_PUBLISHED_PODCASTS"
export const DL_GET_PUBLISHED_PODCASTS_ERROR = "DL_GET_PUBLISHED_PODCASTS_ERROR"
export const DL_SEARCH_PODCASTS = "DL_SEARCH_PODCASTS"
export const DL_SEARCH_PODCASTS_ERROR = "DL_SEARCH_PODCASTS_ERROR"
export const DL_GET_PODCASTS_COUNT = "DL_GET_PODCASTS_COUNT"
export const DL_GET_PODCASTS_COUNT_ERROR = "DL_GET_PODCASTS_COUNT_ERROR"
export const DL_GET_PODCASTS_SEARCH_COUNT = "DL_GET_PODCASTS_SEARCH_COUNT"
export const ADD_PODCAST = "ADD_PODCAST"
export const ADD_PODCAST_ERROR = "ADD_PODCAST_ERROR"
export const UPDATE_PODCAST = "UPDATE_PODCAST"
export const UPDATE_PODCAST_ERROR = "UPDATE_PODCAST_ERROR"
export const DELETE_PODCAST = "DELETE_PODCAST"
export const DELETE_PODCAST_ERROR = "DELETE_PODCAST_ERROR"
export const DL_SELECT_PODCAST = "DL_SELECT_PODCAST"

// Podcast Episodes
export const DL_GET_PODCAST_EPISODES = "DL_GET_PODCAST_EPISODES"
export const DL_GET_PODCAST_EPISODES_ERROR = "DL_GET_PODCAST_EPISODES_ERROR"
export const DL_GET_PUBLISHED_PODCAST_EPISODES = "DL_GET_PUBLISHED_PODCAST_EPISODES"
export const DL_GET_PUBLISHED_PODCAST_EPISODES_ERROR = "DL_GET_PUBLISHED_PODCAST_EPISODES_ERROR"
export const DL_SEARCH_PODCAST_EPISODES = "DL_SEARCH_PODCAST_EPISODES"
export const DL_SEARCH_PODCAST_EPISODES_ERROR = "DL_SEARCH_PODCAST_EPISODES_ERROR"
export const DL_GET_PODCAST_EPISODES_COUNT = "DL_GET_PODCAST_EPISODES_COUNT"
export const DL_GET_PODCAST_EPISODES_COUNT_ERROR = "DL_GET_PODCAST_EPISODES_COUNT_ERROR"
export const DL_GET_PODCAST_EPISODES_SEARCH_COUNT = "DL_GET_PODCAST_EPISODES_SEARCH_COUNT"
export const ADD_PODCAST_EPISODE = "ADD_PODCAST_EPISODE"
export const ADD_PODCAST_EPISODE_ERROR = "ADD_PODCAST_EPISODE_ERROR"
export const UPDATE_PODCAST_EPISODE = "UPDATE_PODCAST_EPISODE"
export const UPDATE_PODCAST_EPISODE_ERROR = "UPDATE_PODCAST_EPISODE_ERROR"
export const DELETE_PODCAST_EPISODE = "DELETE_PODCAST_EPISODE"
export const DELETE_PODCAST_EPISODE_ERROR = "DELETE_PODCAST_EPISODE_ERROR"
export const DL_SELECT_PODCAST_EPISODE = "DL_SELECT_PODCAST_EPISODE"
